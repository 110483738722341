import React from "react";
import { createHubSpotForm, getCurrentYear } from "../../utils/common";
import FooterLink from "./footer-link";
import SocialProfile from "./social-profile";
import ReactMarkdown from "react-markdown";

export default ({
  data: {
    allDatoCmsSocialProfile: { edges: profiles },
    allDatoCmsFooterLink: { edges: links },
    datoCmsSubscribeForm: form
  }
}) => {
  createHubSpotForm({
    formId: form.hubspotFormId,
    targetId: "#hubspotForm",
    css: true
  });
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <h2 className="h2">{form.heading}</h2>
        <div className="sm-spacing" />
        <p className="p center-align whiter reduced">
          <ReactMarkdown source={form.description} escapeHtml={false} />
        </p>
        <div className="sm-spacing" />
        <div className="w-embed w-script subscriber-container">
          <div id="hubspotForm" />
        </div>
        <div className="lg-spacing" />
      </div>

      <FooterLink links={links} />

      <div className="md-spacing" />
      <div className="container grid valign">
        <div className="copyright">
          <div className="copyright-content">
            <div className="footer-txt">
              <span className="made-in">© {getCurrentYear()} Nugit Ltd Pte.  </span>
            </div>
          </div>
          <div className="made-in-content">
            <div className="footer-txt">
              <span className="made-in">Made with </span>
            </div>
            <div className="heart-icon" />
            <div className="footer-txt">
              <span className="made-in"> in Singapore</span>
            </div>
          </div>
        </div>
        <SocialProfile data={profiles} />
      </div>
    </footer>
  );
};
