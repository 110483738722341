import React from "react";

export default ({ data }) => {  
  return (
    <div>
      {data.map(({ node: profile }) => (
        <a
          href={profile.url}
          target="_blank"
          className={`w-inline-block footer-icon`}
        >
          {profile.image && <img src={profile.image.url} alt={profile.image.alt}></img>}
        </a>
      ))}
    </div>
  );
}
