import React from "react";

export default ({ data }) => {
  return (
    <React.Fragment>
      {data.map(item => (
        <script
          key={item.id}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `${item.jsonSchema}`
          }}
        />
      ))}
    </React.Fragment>
  );
};
