import React from "react";
import { Helmet } from "react-helmet";

export default ({ data }) => {
  let vimeoTrackingUrl = !!data.vimeoAnalyticsKey && (
    <script
      type="text/javascript"
      src={`https://extend.vimeocdn.com/ga/${+data.vimeoAnalyticsKey}.js`}
    />
  );

  let hubSpotTracking = (
    <script
      type="text/javascript"
      id="hs-script-loader"
      async=""
      defer=""
      src="//js.hs-scripts.com/1859491.js"
    />
  );

  return (
    <React.Fragment>
      <Helmet>
        {vimeoTrackingUrl}
        {hubSpotTracking}
      </Helmet>

    </React.Fragment>
  );
};
