
// // DEVELOPMENT
// import $ from 'jquery';
// import Webflow from 'Webflow';
// import hbspt from 'hbspt';

export const NUGIT_NUMBER_OF_VISITED_PAGE = "NUGIT_NUMBER_OF_VISITED_PAGE";
export const NUGIT_DATE_FORMAT = "dddd, Do MMMM YYYY";
export const NUGIT_TIME_FORMAT = "hh.mm A";

export function resetWebflow(pageId) {
  try {
    setPageId(pageId);
    setTimeout(() => {
      try {
        displayOnlyContentLayout(false);
        Webflow.ready();
        //https://forum.webflow.com/t/reinitialize-webflow-ix2/51094/8
        Webflow.require("ix2").init();
      } catch (e) { }
    });
    Webflow.destroy();
  } catch (e) { }
}

function setPageId(pageId) {
  if (pageId) {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("data-wf-page", pageId);
  }
}

export function filterItemsByApiKey(items, apiKey, isFind) {
  items = items || [];
  if (isFind) {
    return items.find((item) => item.model && item.model.apiKey === apiKey);
  } else {
    return items.filter((item) => item.model && item.model.apiKey === apiKey);
  }
}

export function isPastADate(date) {
  let now = new Date();
  return now > new Date(date);
}

export function formatDateRange(fullFormat, fromDate, toDate) {
  fullFormat = fullFormat || `${NUGIT_TIME_FORMAT}, ${NUGIT_DATE_FORMAT}`;
  if (!fromDate.isValid()) return "";
  let formatedFromDate = fromDate.format(fullFormat);

  if (!getSafe(() => toDate.isValid())) return formatedFromDate;

  let isSameDate = fromDate.clone().startOf("day").isSame(toDate, "d");

  if (isSameDate) {
    return `${fromDate.format(NUGIT_TIME_FORMAT)} - ${toDate.format(
      fullFormat
    )}`;
  }
  return `${formatedFromDate} - ${toDate.format(fullFormat)}`;
}

export function displayOnlyContentLayout(show = true) {
  try {
    setTimeout(() => {
      try {
        $(".navbar.w-nav").toggle(!show);
        $("#footer").toggle(!show);
        $("#demo").toggle(!show);
      } catch (error) { }
    }, 100);
  } catch (e) { }
}

export function createHubSpotForm({
  formId,
  targetId,
  portalId = "1859491",
  css = "",
  redirectUrl = "",
  inlineMessage = "",
  rawInlineMessage = "",
  goToWebinarWebinarKey = "",
}) {
  try {
    if (css) {
      css =
        "form{display: flex; align-items: baseline; -webkit-align-items: baseline; -ms-flex-align: baseline; justify-content: space-between; -webkit-justify-content: space-between; flex-wrap: wrap; -ms-flex-wrap: wrap; -webkit-flex-wrap: wrap;}";
    }
    setTimeout(() => {
      try {
        hbspt.forms.create({
          portalId,
          formId,
          target: targetId,
          css,
          redirectUrl,
          inlineMessage,
          rawInlineMessage,
          goToWebinarWebinarKey,
        });
      } catch (error) { }
    });
  } catch (error) { }
}

export function setLocalStorageData(key, value) {
  return getSafe(() => localStorage.setItem(key, value));
}

export function trimEndTrailingSlash(url) {
  return url.replace(/\/$/, "");
}

export function getSafe(fn) {
  try {
    return fn();
  } catch (e) {
    return "";
  }
}

export function isModelOfType(item, type) {
  return getSafe(() => item.model.apiKey) === type;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    if (!map.has(key)) {
      map.set(key, [item]);
    } else {
      map.get(key).push(item);
    }
  });
  return map;
}

export function getCurrentYear() {
  var now = new Date();
  return now.getFullYear();
}

export function checkIfHrefIsFullURL(url) {
  var pattern = /^((http|https|ftp):\/\/)/;
  return pattern.test(url);
}

export function parseVideo(url) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );

  if (RegExp.$3.indexOf("youtu") > -1) {
    var type = "youtube";
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    var type = "vimeo";
  }

  return {
    type: type,
    id: RegExp.$6,
  };
}

export function createVideo(url, width = "100%", height = "100%") {
  // Returns an iframe of the video with the specified URL.
  var videoObj = parseVideo(url);
  var $iframe = $("<iframe>", {
    width: width,
    height: height,
  });
  $iframe.css({
    position: "absolute",
    top: 0,
    left: 0,
  });
  $iframe.attr({
    frameborder: 0,
    webkitallowfullscreen: "",
    mozallowfullscreen: "",
    allowfullscreen: "",
  });
  if (videoObj.type == "youtube") {
    $iframe.attr("src", "//www.youtube.com/embed/" + videoObj.id);
  } else if (videoObj.type == "vimeo") {
    $iframe.attr("src", "//player.vimeo.com/video/" + videoObj.id);
  }
  return $iframe;
}

export function sortRecords(list) {
  if (!list) {
    return [];
  }
  list.sort(({ node: a }, { node: b }) => a.position - b.position);
}

export function resizeAndConvertImage(
  width = 1400,
  format = "jpg",
  quality = 75
) {
  return `?fm=${format}&q=${quality}&w=${width}`;
}

export function replaceContentWithImgSize(content, size = 1400) {
  try {
    let $content = $(`<div>${content}</div>`);
    let $images = $content.find("img");
    for (let i = 0; i < $images.length; i++) {
      let $image = $images.eq(i);
      let src = $image.attr("src");
      if (src) {
        $image.attr("src", `${src}${resizeAndConvertImage(size)}`);
      }
    }
    return $content.html();
  } catch (error) { }
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, "g"), replace);
}