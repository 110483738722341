import { Link } from "gatsby";
import React from "react";
import * as util from "../../utils/common";
import { createHubSpotForm } from "../../utils/common";

class Header extends React.Component {
  componentDidMount() {

  }

  render() {
    let { topNav } = this.props;

    return (
      <React.Fragment>
        <div className="navbar-container">
          <div
            id="NotificationContainer"
            style={{
              display: "none"
            }}
          >
            <div id="NotificationContent" />
            <a
              id="NotificationCloseBtn"
              href="#"
              className="close-w-top w-inline-block"
            />
          </div>
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration="400"
            data-easing="ease-in-out"
            data-easing2="ease-in-out"
            data-w-id="3c6cfdb0-ea9e-799f-4b62-af73be7456a4"
            className="navbar w-nav"
          >
            <div className="container-nav w-clearfix">
              <Link to="/" className="logo-nav w-nav-brand w--current" />
              <nav
                role="navigation"
                className="nav-menu-container w-nav-menu"
              >
                <Link
                  to="/"
                  className="nav-link w-hidden-main w-nav-link w--current"
                >
                  Home
                </Link>
                {topNav &&
                  topNav.map(({ node: item }) => renderTopNavLink(item))}
                <a
                  href="http://app.nugit.co/"
                  target="_blank"
                  className="nav-link w-hidden-main w-nav-link"
                >
                  Login
                </a>
                <Link to="/request-demo"
                  className="btn small mobile-menu w-button tracking-demo"
                >Request Demo</Link>              
              </nav>
              <div className="cta-nav">
                <a
                  href="http://app.nugit.co/"
                  target="_blank"
                  className="nav-link w-hidden-medium w-hidden-small w-hidden-tiny w-nav-link"
                >
                  Login
                </a>
                <Link to="/request-demo"
                  className="btn small top-space w-button tracking-demo"
                >
                  Request Demo
                </Link>
                <div className="w-embed" />
              </div>
              <div className="mobile-menu-icon w-nav-button">
                <div
                  data-w-id="46b8219d-98d6-91ca-a315-851852012b51"
                  className="line first"
                />
                <div
                  data-w-id="d7bab228-6401-cd2f-c50f-6ec9d2628756"
                  className="line"
                />
                <div
                  data-w-id="d9b59824-7cd5-ba6a-c9dd-7a0fc6500aec"
                  className="line last"
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function renderTopNavLink(item, addtionalClass = "") {
  let hasChildrenLink = util.getSafe(() => item.treeChildren.length);
  if (hasChildrenLink) {
    item.treeChildren = item.treeChildren.sort((a, b) => a.position - b.position);
    return (
      <div data-delay="0" className="nav-link-dropdown w-dropdown">
        <div className="dropdown-toggle w-dropdown-toggle">
          <div className="arrow-down" />
          <div>{item.title}</div>
        </div>
        <nav className="dropdown-list-bg w-dropdown-list">
          {item.treeChildren.map(childLink =>
            renderTopNavLink(childLink, "dropdown-menu w-dropdown-link")
          )}
        </nav>
      </div>
    );
  }

  return util.checkIfHrefIsFullURL(item.topNavLink) ?
    (
      <a
        href={`${item.topNavLink}`}
        className={addtionalClass ? addtionalClass : "nav-link w-nav-link"}
        key={item.id}
      >
        {item.title}
      </a>
    ) :
    (
      <Link
        to={`/${item.topNavLink}`}
        className={addtionalClass ? addtionalClass : "nav-link w-nav-link"}
        key={item.id}
      >
        {item.title}
      </Link>
    );
}
export default Header;
