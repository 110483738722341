import React from "react";
import Link from "../common/custom-link";
import logoWhite from "../../styles/images/logo-white.svg";

export default ({ links }) => {
  return (
    <div className="container grid">
      <Link
        to="/"
        className="footer-logo w-hidden-tiny w-inline-block w--current"
      >
        <img src={logoWhite} width="80" />
      </Link>
      {links.map(({ node: link }) => {        
        link.treeChildren = link.treeChildren && link.treeChildren.sort((a, b) => a.position - b.position)
        return <div className="footer-col" key={link.id}>
          <div className="heading-small">{link.title}</div>
          {link.treeChildren.map(childLink => (
            <React.Fragment key={childLink.id}>
              <div className="sm-spacing" />
              <Link to={childLink.cta} className="link-footer">
                {childLink.title}
              </Link>
            </React.Fragment>
          ))}
        </div>;
      })}
    </div>
  );
};
